import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import cartReducer from './slices/cartSlice';
import ticketsReducer from './slices/ticketsSlice';
import eventsReducer from './slices/eventsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    tickets: ticketsReducer,
    events: eventsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;