import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  selectedEvent: null,
  filters: {
    status: null,
    startDate: null,
    endDate: null,
    search: '',
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
  },
  loading: false,
  error: null,
  changeDetected: false,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setEvents: (state, action) => {
      state.items = action.payload;
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    updateEvent: (state, action) => {
      const index = state.items.findIndex(event => event.id === action.payload.id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
      if (state.selectedEvent?.id === action.payload.id) {
        state.selectedEvent = action.payload;
      }
    },
    setFilter: (state, action) => {
      state.filters[action.payload.key] = action.payload.value;
      // Reset to first page when filters change
      state.pagination.currentPage = 1;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
      state.pagination.currentPage = 1;
    },
    setPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.pagination.totalPages = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.pagination.itemsPerPage = action.payload;
      state.pagination.currentPage = 1;
    },
    setChangeDetected: (state, action) => {
      state.changeDetected = action.payload;
    },
  }
});

export const {
  setLoading,
  setError,
  setEvents,
  setSelectedEvent,
  updateEvent,
  setFilter,
  clearFilters,
  setPage,
  setTotalPages,
  setItemsPerPage,
  setChangeDetected,
} = eventsSlice.actions;

// Selectors
export const selectEvents = (state) => state.events.items;
export const selectSelectedEvent = (state) => state.events.selectedEvent;
export const selectEventsLoading = (state) => state.events.loading;
export const selectEventsError = (state) => state.events.error;
export const selectEventsFilters = (state) => state.events.filters;
export const selectEventsPagination = (state) => state.events.pagination;
export const selectChangeDetected = (state) => state.events.changeDetected;

export default eventsSlice.reducer;