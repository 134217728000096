import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  filters: {
    status: null,
    startDate: null,
    endDate: null,
  },
  pagination: {
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 20,
  },
  loading: false,
  error: null,
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTickets: (state, action) => {
      state.items = action.payload;
    },
    addTicket: (state, action) => {
      state.items.push(action.payload);
    },
    updateTicket: (state, action) => {
      const index = state.items.findIndex(ticket => ticket.id === action.payload.id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    },
    removeTicket: (state, action) => {
      state.items = state.items.filter(ticket => ticket.id !== action.payload);
    },
    setFilter: (state, action) => {
      state.filters[action.payload.key] = action.payload.value;
      // Reset to first page when filters change
      state.pagination.currentPage = 1;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
      state.pagination.currentPage = 1;
    },
    setPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.pagination.totalPages = action.payload;
    },
    setItemsPerPage: (state, action) => {
      state.pagination.itemsPerPage = action.payload;
      state.pagination.currentPage = 1; // Reset to first page when changing items per page
    },
  }
});

export const {
  setLoading,
  setError,
  setTickets,
  addTicket,
  updateTicket,
  removeTicket,
  setFilter,
  clearFilters,
  setPage,
  setTotalPages,
  setItemsPerPage,
} = ticketsSlice.actions;

// Selectors
export const selectTickets = (state) => state.tickets.items;
export const selectTicketsLoading = (state) => state.tickets.loading;
export const selectTicketsError = (state) => state.tickets.error;
export const selectTicketsFilters = (state) => state.tickets.filters;
export const selectTicketsPagination = (state) => state.tickets.pagination;

export default ticketsSlice.reducer;