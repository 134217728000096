// This file is kept for backwards compatibility
// and provides the same interface as the old user slice
import { createSlice } from '@reduxjs/toolkit';
import { selectToken, selectIsAuthenticated, selectUserType } from '../store/slices/authSlice';

// This userSlice is a compatibility layer that uses the new authSlice
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      token: '',
      type: '',
      mktPermission: false,
    },
    isAuthenticated: false
  },
  reducers: {
    login: (state, action) => {
      state.user.token = action.payload.token;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user.token = '';
      state.user.type = '';
      state.isAuthenticated = false;
      state.user.mktPermission = false;
    },
    setType: (state, action) => {
      state.user.type = action.payload;
    },
    setMktPermission: (state, action) => {
      state.user.mktPermission = action.payload;
    }
  }
});

export const { login, logout, setType, setMktPermission } = userSlice.actions;

// Selector that adapts to the new structure by using the authSlice selector
export const selectUser = (state) => {
  const token = selectToken(state);
  const userType = selectUserType(state);
  if (!token) return { token: '', type: '', mktPermission: false };
  
  return {
    token,
    type: userType || '',
    mktPermission: false
  };
};

export default userSlice.reducer;