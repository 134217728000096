import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  userType: '',
  isAuthenticated: false,
  permissions: {
    marketing: false,
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.token = '';
      state.userType = '';
      state.isAuthenticated = false;
      state.permissions.marketing = false;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setPermission: (state, action) => {
      state.permissions[action.payload.key] = action.payload.value;
    }
  }
});

export const { login, logout, setUserType, setPermission } = authSlice.actions;

// Selectors
export const selectAuth = (state) => state.auth;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectToken = (state) => state.auth.token;
export const selectUserType = (state) => state.auth.userType;

export default authSlice.reducer;