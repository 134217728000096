import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  total: 0,
  uiState: {
    searchBarExpanded: false,
  }
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const item = action.payload;
      state.items.push(item);
      state.total += item.price;
    },
    removeItem: (state, action) => {
      const itemId = action.payload.id;
      const itemIndex = state.items.findIndex(item => item.id === itemId);
      
      if (itemIndex !== -1) {
        const removedItem = state.items[itemIndex];
        state.total -= removedItem.price;
        state.items.splice(itemIndex, 1);
      }
    },
    removeItemAll: (state, action) => {
      const itemId = action.payload.id;
      const removedItems = state.items.filter(item => item.id === itemId);
      const removedTotal = removedItems.reduce((sum, item) => sum + item.price, 0);
      
      state.total -= removedTotal;
      state.items = state.items.filter(item => item.id !== itemId);
    },
    clearCart: (state) => {
      state.items = [];
      state.total = 0;
    },
    toggleSearchBar: (state, action) => {
      state.uiState.searchBarExpanded = action.payload ?? !state.uiState.searchBarExpanded;
    },
  }
});

export const { 
  addItem, 
  removeItem, 
  removeItemAll, 
  clearCart, 
  toggleSearchBar 
} = cartSlice.actions;

// Selectors
export const selectCart = (state) => state.cart;
export const selectCartItems = (state) => state.cart.items;
export const selectCartTotal = (state) => state.cart.total;
export const selectSearchBarExpanded = (state) => state.cart.uiState.searchBarExpanded;

export default cartSlice.reducer;