import React, { Suspense, lazy, useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useNavigate, 
    Navigate
} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import { login } from './store/slices/authSlice';
import DashboardLayout from './components/layouts/DashboardLayout';
import Expenses from './components/Expenses';
import RetiroSalaEmergente from './components/RetiroSalaEmergente';

// Lazy load components for better performance
const Home = lazy(() => import('./features/dashboard/Home'));
const EventPayment = lazy(() => import('./features/events/EventPayment'));
const EventList = lazy(() => import('./features/events/EventList'));
const EventDetails = lazy(() => import('./features/events/EventDetails'));
const ProofOfTicket = lazy(() => import('./features/tickets/ProofOfTicket'));
const ModifyTicket = lazy(() => import('./features/tickets/ModifyTicket'));
const Login = lazy(() => import('./features/auth/Login'));
const ProducerAuth = lazy(() => import('./features/auth/ProducerAuth'));
const ExpensesDetails = lazy(() => import('./features/finance/ExpensesDetails'));
const BalanceSheet = lazy(() => import('./features/finance/BalanceSheet'));
const ProfitAndLoss = lazy(() => import('./features/finance/ProfitAndLoss'));

// Loading fallback
const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// URL Parameter Handlers
const OrderId = () => {
    const { id } = useParams();
    return <ProofOfTicket id={id} />;
};

const EventId = () => {
    const { id } = useParams();
    return <EventDetails id={id} />;
};

// Token Validation Component
function AppWrapper() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    // Token validation function
    const validateToken = async (token) => {
        try {
            const response = await fetch('https://internal-api.flashpass.com.ar/check-jwt.php', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'same-origin',
                    Authorization: `Bearer ${token}`,
                },
            });

            let json = null;
            try {
                json = await response.json();
            } catch (error) {
                // Handle non-JSON response error
            }

            // Invalid token check
            if (!response.ok || json?.status === 'error' || response.status === 401) {
                window.localStorage.removeItem('token');
                navigate('/login');
            } else {
                // Valid token
                dispatch(login({ token }));
            }
        } catch (error) {
            // Network or other error
            window.localStorage.removeItem('token');
            navigate('/login');
        }
    };

    // Component mount effect
    useEffect(() => {
        const storedToken = window.localStorage.getItem('token');
        if (!storedToken) {
            navigate('/login');
        } else {
            validateToken(storedToken);
        }
    }, [dispatch, navigate]);

    return (
        <Suspense fallback={<LoadingFallback />}>
            <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />

                {/* Protected routes with dashboard layout */}
                <Route path="/" element={<DashboardLayout />}>
                    <Route index element={<Home />} />
                    <Route path="event-payment" element={<EventPayment />} />
                    <Route path="retiro-sala-emergente" element={<RetiroSalaEmergente />} />
                    <Route path="transactions/new" element={<Expenses />} />
                    <Route path="transactions" element={<ExpensesDetails />} />
                    <Route path="financial-statement" element={<BalanceSheet />} />
                    <Route path="profit-and-loss-statement" element={<ProfitAndLoss />} />
                    <Route path="event-list" element={<EventList />} />
                    <Route path="event/new" element={<EventDetails />} />
                    <Route path="event/:id" element={<EventId />} />
                    <Route path="order/:id" element={<OrderId />} />
                    <Route path="modify-ticket" element={<ModifyTicket />} />
                    <Route path="producer-auth" element={<ProducerAuth />} />
                </Route>

                {/* Fallback route */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    );
}

// Main App Component
function App() {
    return (
        <Provider store={store}>
            <div className="App min-h-screen bg-gray-50">
                <BrowserRouter>
                    <AppWrapper />
                </BrowserRouter>
            </div>
        </Provider>
    );
}

export default App;