import React, { useState, useEffect, useCallback, memo } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authSlice';

// Function to get icon for each menu item
const getMenuIcon = (path) => {
  // Default icon for fallback
  const defaultIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <rect x="3" y="3" width="7" height="7"></rect>
      <rect x="14" y="3" width="7" height="7"></rect>
      <rect x="14" y="14" width="7" height="7"></rect>
      <rect x="3" y="14" width="7" height="7"></rect>
    </svg>
  );
  
  // Map paths to specific icons
  if (path.includes('dashboard') || path === '/') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="3" width="7" height="9"></rect>
        <rect x="14" y="3" width="7" height="5"></rect>
        <rect x="14" y="12" width="7" height="9"></rect>
        <rect x="3" y="16" width="7" height="5"></rect>
      </svg>
    );
  } else if (path.includes('event')) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
    );
  } else if (path.includes('ticket')) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M20 12v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-6"></path>
        <path d="M12 3v15"></path>
        <polyline points="6 8 12 3 18 8"></polyline>
      </svg>
    );
  } else if (path.includes('balance') || path.includes('finance')) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="12" y1="1" x2="12" y2="23"></line>
        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
      </svg>
    );
  } else if (path.includes('producer')) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
    );
  }
  
  return defaultIcon;
};

// Memoized sidebar item component for better performance
const SidebarItem = memo(({ path, description, isActive, onItemClick }) => (
  <li>
    <Link
      to={path}
      className={`flex items-center py-2.5 px-4 rounded-md transition duration-200 ${
        isActive
          ? 'bg-blue-600 text-white shadow-md'
          : 'text-gray-300 hover:bg-slate-700/70 hover:text-white'
      }`}
      onClick={onItemClick}
    >
      {/* Icon based on menu item */}
      <span className={`mr-3 flex-shrink-0 ${isActive ? 'text-white' : 'text-gray-400'}`}>
        {getMenuIcon(path)}
      </span>
      <span className="truncate">{description}</span>
      
      {/* Active indicator */}
      {isActive && (
        <span className="ml-auto w-1.5 h-1.5 rounded-full bg-white"></span>
      )}
    </Link>
  </li>
));

SidebarItem.displayName = 'SidebarItem';

// Navigation and menu components
const Sidebar = ({ isOpen, options, onCloseSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to handle logo click - navigate to home
  const handleLogoClick = () => {
    navigate('/');
    if (window.innerWidth < 768) { // Only close for mobile
      onCloseSidebar();
    }
  };

  // Function to handle sidebar item click - close sidebar on mobile
  const handleItemClick = () => {
    if (window.innerWidth < 768) {
      onCloseSidebar();
    }
  };

  return (
    <aside
      className={`fixed inset-y-0 left-0 bg-slate-800 text-white w-64 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out md:translate-x-0 z-30 flex flex-col shadow-xl overflow-hidden`}
    >
      {/* Close button visible on mobile */}
      <button 
        className="absolute top-4 right-4 text-gray-400 hover:text-white md:hidden focus:outline-none"
        onClick={onCloseSidebar}
        aria-label="Close sidebar"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      
      <div className="flex-1 overflow-y-auto p-6">
        <div 
          className="flex items-center justify-center mb-8 cursor-pointer transition-transform hover:scale-105"
          onClick={handleLogoClick}
          role="button"
          tabIndex={0}
          aria-label="Go to home page"
        >
          <img
            src="/logo512.png"
            alt="FlashPass Logo"
            className="h-12"
          />
        </div>

        <div className="mb-6 px-3 py-2 rounded-md bg-slate-700/50">
          <div className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-2">
            Menú principal
          </div>
          <nav>
            <ul className="space-y-1.5">
              {options.map((option) => (
                <SidebarItem
                  key={option.name}
                  path={option.name}
                  description={option.description}
                  isActive={location.pathname === option.name || location.pathname.startsWith(`${option.name}/`)}
                  onItemClick={handleItemClick}
                />
              ))}
            </ul>
          </nav>
        </div>
      </div>
      
      {/* Footer area with app version */}
      <div className="p-4 border-t border-slate-700/60 bg-slate-800/80 text-center">
        <div className="text-xs text-gray-400">
          FlashPass Internal v2.0
        </div>
      </div>
    </aside>
  );
};

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  // Get current page title based on pathname
  const getPageTitle = () => {
    // Remove leading slash and get the first part of the path
    const path = location.pathname.substring(1).split('/')[0];
    
    // Format the title - capitalize first letter and separate by space if camelCase
    if (!path) return 'Dashboard';
    
    return path
      .replace(/-/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    dispatch(logout());
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <header className="bg-white shadow-sm h-16 flex items-center px-4 md:px-6 sticky top-0 z-20">
      <button
        onClick={toggleSidebar}
        className="md:hidden mr-3 text-gray-600 hover:text-gray-900 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md p-1"
        aria-label="Toggle sidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>

      {/* Page title */}
      <div className="font-medium text-gray-800 text-base md:text-lg md:ml-2 truncate">
        {getPageTitle()}
      </div>

      <div className="flex-1"></div>

      <div className="flex items-center">
        <button
          onClick={handleLogout}
          className="text-gray-600 hover:text-red-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 px-2 md:px-3 py-1.5 rounded-md flex items-center text-sm md:text-base"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 md:mr-1.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
            <polyline points="16 17 21 12 16 7" />
            <line x1="21" y1="12" x2="9" y2="12" />
          </svg>
          <span className="hidden sm:inline">Cerrar sesión</span>
        </button>
      </div>
    </header>
  );
};

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prev) => !prev);
  }, []);
  
  const closeSidebar = useCallback(() => {
    setSidebarOpen(false);
  }, []);

  const fetchMenuOptions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        'https://internal-api.flashpass.com.ar/user-permissions',
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            credentials: 'same-origin',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data.status === 'success') {
        setMenuOptions(data.data);
      } else {
        console.error('Failed to fetch menu options:', data);
      }
    } catch (error) {
      console.error('Error fetching menu options:', error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    fetchMenuOptions();
  }, [token, navigate, fetchMenuOptions]);

  return (
    <div className="flex h-screen bg-gray-50 overflow-hidden">
      {/* Overlay for mobile when sidebar is open */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 z-20 md:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}

      {/* Sidebar */}
      <Sidebar isOpen={sidebarOpen} options={menuOptions} onCloseSidebar={closeSidebar} />

      {/* Main content */}
      <div className="flex-1 flex flex-col w-full transition-all duration-300 md:ml-64">
        <Header toggleSidebar={toggleSidebar} />

        <main className="flex-1 p-4 md:p-6 overflow-auto">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : (
            <div className="w-full max-w-full">
              <Outlet />
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;